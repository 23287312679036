@use "assets/scss/front/abstracts" as *;

.sdg-homepage {
  margin-top: 50px;
  &__welcome {
    margin-bottom: 50px;
    &-text {
      p {
        margin: 0;
        &:nth-child(1) {
          margin-top: 20px;
          font-size: 32px;
          line-height: 64px;
          font-weight: 300;
        }
        &:nth-child(2) {
          font-size: 48px;
          line-height: 64px;
          font-weight: 700;
          margin-bottom: 25px;
        }
        &:nth-child(3) {
          line-height: 28px;
          opacity: 0.7;
          margin-bottom: 50px;
        }
      }
    }
    &-logo {
      img {
        &:nth-child(1) {
          filter: drop-shadow(0px 16px 24px rgba(26, 201, 218, 0.32));
        }
      }
    }
  }

  &__articleInfo {
    margin-bottom: 6.25rem;
    &-title {
      display: flex;
      align-items: center;
      flex-direction: column;
      h3 {
        font-size: 20px;
        letter-spacing: 0.05em;
        margin-bottom: 0;
        text-transform: uppercase;
        margin-bottom: 1.5rem;
      }
    }
  }

  &__dashboard {
    &-left {
      img {
        width: 95%;
      }
    }
    &-right {
      p {
        &:nth-child(2) {
          font-weight: 700;
          font-size: 40px;
          line-height: 48px;
          margin-top: 2rem;
          margin-bottom: 1.25rem;
        }
        &:nth-child(3) {
          line-height: 19px;
          margin-bottom: 2.5rem;
        }
        &:nth-child(4) {
          line-height: 28px;
          opacity: 0.7;
          margin-bottom: 2.5rem;
        }
      }
    }
  }

  &__border-glow {
    border: 4px solid #009EDB;
    box-shadow: 0px 4px 16px rgba(26, 201, 218, 0.84);
    width: 70px;

  }
}

@media (max-width: 575.98px) {
  .sdg-homepage {
    margin-top: 1.5rem;

    &__welcome {
      margin-bottom: 3.125rem;

      &-text {
        p {
          &:nth-child(1) {
            font-size: 1rem;
            line-height: 48px;
            margin-top: 0;
          }
          &:nth-child(2) {
            font-size: 2rem;
            line-height: 48px;
            margin-bottom: 1.5rem;
          }
          &:nth-child(3) {
            font-size: .875rem;
            margin-bottom: 2rem;
          }
        }
      }
      &-logo {
        margin-top: 3rem;
        text-align: center;

        img {
          &:nth-child(1) {
            width: 100%;
          }
        }
      }
    }

    &__articleInfo {
      margin-bottom: 4.25rem;
    }

    &__dashboard {

      &-left {
        img {
          width: 100%;
        }
      }

      &-right {
        margin-top: 2.5rem;

        p {
          &:nth-child(2) {
            font-size: 1.5rem;
            line-height: 28px;
          }
          &:nth-child(3) {
            font-size: .75rem;
            margin-bottom: 2rem;
          }
          &:nth-child(4) {
            font-size: .875rem;
            line-height: 24px;
          }
        }
      }
    }
  }
}