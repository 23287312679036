@import "assets/scss/front/abstracts/variables";

.sdg-footer {
  margin-top: 100px;
  &__logo {
    display: flex;
    flex-direction: column;
    img {
      width: 30%;
      margin-bottom: 1rem;
    }
    p {
      &:nth-child(2) {
        font-size: 1rem;
        font-weight: 700;
      }
      &:nth-child(3) {
        font-size: .8rem;
        opacity: .7;
      }
    }
  }
  &__title {
    font-size: $font-size-small;
    text-transform: uppercase;
    color: $color-secondary-light;
    margin-bottom: 20px;
  }
  &__menu {
    display: flex;
    flex-direction: column;
    font-size: $font-size-smaller; 
  }
  &__menu-list {
    margin-bottom: 20px;
    a {
      color: $white;
      &:hover {
        text-decoration: none;
        @extend %text-blue-linier;
      }
    }
  }
  &__copyright {
    justify-content: center;
    font-size: $font-size-small;
    opacity: 0.7;
    padding: 50px 0;
  }
}

@media (max-width: 575.98px) {
  .sdg-footer {
    &__logo {
      align-items: center;
      img {
        width: 30%;
      }
      p {
        &:nth-child(2) {
          font-size: .875rem;
          font-weight: 700;
          text-align: center;
        }
        &:nth-child(3) {
          text-align: center;
        }
      }
    }
    &__menu {
      margin-bottom: .5rem;
      border-bottom: 1px solid rgb(75, 200, 245, 0.3);
      &--active {
        border-bottom: none;
      }
      &-title {
        display: flex;
        justify-content: space-between;
        font-size: .875rem;
        text-transform: uppercase;
        color: $color-secondary-light;
        padding-bottom: 1rem;
      }
      &-list {
        margin-bottom: 1rem;
      }
    }
    &__copyright {
      justify-content: flex-start;
      margin-right: 0!important;
      margin-left: 0!important;
      padding: 1rem 0 .3rem 0;
    }
  }
}