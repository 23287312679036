@use "assets/scss/admin/abstracts" as *;

.sdg-admin-footer {
  font-family: 'Roboto', sans-serif;
  font-size: $font-size-smaller;
  color: rgba($color-admin-primary-2, .5);
  

  &__link {
    color: rgba($color-admin-primary-2, .5);
    padding: 0 .2rem;

    &:not(:last-child) {
      border-right: 1px solid rgba($color-admin-primary-2, .5);
    }
  }
}