@use './variables' as *;

%input {
  font-size: $font-size-small;
  font-family: 'Roboto', sans-serif;
  background-color: transparent!important;
  transition: .2s all;

  &::placeholder {
    font-size: $font-size-small;
    color: rgba($white, .7)!important;
  }
}

%tentang-text {
  color: rgba($white, .7);
  line-height: 1.94rem;
  max-width: 75%;
}