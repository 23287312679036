.chart {
  &-container {
    background-color: #132442;
    border-radius: 16px;
    padding: 3rem 2rem;
  }
}

@media (max-width: 575.98px) {
  .chart {
    &-container {
      padding: 2rem 1rem;
    }
  }
}
