@use "assets/scss/front/abstracts/variables";

.sdg-header {
  margin-top: 3rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  &__title {
    @extend %text-blue-linier;
    margin-bottom: 20px;
    font-weight: 700;
    font-size: 48px;
    line-height: 57px;
    text-shadow: 0px 8px 24px rgba(26, 201, 218, 0.56);
  }
  &__subtitle {
    font-size: 19px;
    line-height: 22px;
    text-transform: uppercase;

    @media (max-width: 575.98px) {
      font-size: 1rem;
    }
  }
  &__mobile{
    display: none;
  }
  &__desktop{
    display: block;
  }
}

@media screen and (max-width:575.98px) {
  .sdg-header {
    &__title {
      font-size: 32px;
    }
    &__mobile{
      display: block;
    }
    &__desktop{
      display: none;
    }
  }
}