@use "assets/scss/front/abstracts" as *;

.sdg-knowledgehub {
  &-container {
    margin-top: 6rem;
  }

  &__title {
    text-align: center;
    font-size: 1rem;
    line-height: 19px;
    letter-spacing: 0.1rem;
    opacity: 0.8;
    margin-bottom: 6rem;

    &::before {
      background-color: #4BC8F5;
      content: "";
      display: inline-block;
      height: 1px;
      position: relative;
      vertical-align: middle;
      width: 5%;
      right: 0.5em;
      margin-left: -50%;
    }

    &::after {
      background-color: #4BC8F5;
      content: "";
      display: inline-block;
      height: 1px;
      position: relative;
      vertical-align: middle;
      width: 5%;
      left: 0.5em;
      margin-right: -50%;
    }
  }

  &__content {
    margin-bottom: 4.25rem;
  }
}

@media (max-width: 575.98px) {
  .sdg-knowledgehub {
    &-container {
      margin-top: 4rem;
    }

    &__title {
      margin-bottom: 2.5rem;
      &::before {
        width: 15%;
      }
      &::after {
        width: 15%;
      }
    }
  }
}