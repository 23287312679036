.sdg-admin-manage {
  &__back-icon {
    cursor: pointer;
  }

  &__title {
    font-size: 1rem;
    font-family: 'Roboto', sans-serif;
    color: #111;
    text-transform: uppercase;
  }
}