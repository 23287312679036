@use "../abstracts/" as *;

.sdg-admin-btn {
  cursor: pointer;
  &--upload {
    color: $color-admin-primary-2;
    font-family: 'Roboto', sans-serif;
    font-size: 1rem;
    font-weight: 500;
    background-color: $white;
    border-color: $color-admin-grey-2;
    border-radius: 8px;
  
    .sdg-admin-btn__icon path {
      transition: .2s all;
    }
  
    &:hover {
      color: $white;
      background-color: $color-admin-primary-2;
  
      & .sdg-admin-btn__icon path {
        fill: $white;
      }
    }
  }

  &--cancel {
    color: $color-admin-primary-2;
    font-size: $font-size-small;
    font-weight: 700;
  }
}