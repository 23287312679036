.sdg-front-detail__link{
  text-decoration: none;
  color: $white;
  opacity: 0.7;

  &:hover{
    text-decoration: none;
    color: $white;
    opacity: 1;
  }
}
