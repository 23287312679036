@use "../abstracts/" as *;

.sdg-admin-manage {
  &__input,&__input-textarea {
    padding: 1.5rem 1rem;
    border-radius: 10px;

    &::placeholder {
      font-size: $font-size-small;
      color: rgba($black, .38);
    }

    &--error {
      border: 1px solid red;
    }

    &--password {
      display: inline-block;
      margin-right: -2.5rem;
    }
  }
  &__input-textarea {
    padding: 1rem;
  }
}