@use "../abstracts/" as *;

.sdg-admin-manage {
  &__helper-text {
    color: $color-admin-grey;
    display: block;
    font-size: $font-size-small;
  }

  &__text {
    &--medium {
      font-size: 18px;
    }
  }
}