@use "assets/scss/front/abstracts" as *;

.sdg-tentang {
  &__language {
    &-option {
      color: $white;
      transition: .2s all;

      &:hover {
        color: $color-secondary-light;
        text-decoration: none;
      }

      &--active {
        font-weight: 700;
        color: $color-secondary-light;
        border-bottom: 2px solid rgba($color-secondary-light, .5);
        text-shadow: 0 .5rem 1rem rgba($color-secondary-light, 8);
      }
    }
  }
}