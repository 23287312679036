@use "assets/scss/front/abstracts" as *;

.sdg-login {
  color: $black;
  background: url(../../../assets/img/bg-login.png) no-repeat center center fixed, #101D35;
  background-size: cover;
  min-height: 100vh;

  &__banner {
    width: 45%;
  }

  &__card {
    border-radius: 1rem!important;

    &-body {
      padding: 2.5rem!important;
    }

    &-logo {
      display: flex;
      justify-content: center;
      margin-bottom: 1rem;

      img {
        width: 25%;
      }
    }

    p.card-title {
      margin-bottom: .3rem;
      font-weight: 300;
    }

    &-copyright {
      text-align: center;
      margin: 2rem 0 0;
      font-size: .75rem;
    }
  }

  &__btn-login {
    font-size: 1rem!important;
    padding: 0.75rem 1rem!important; 
    border-radius: 1.5rem!important;
    background-color: #101D35!important;
    border-color: #101D35!important;

    &:hover {
      opacity: .9;
    }
  }
}