.legend {
  position: absolute;
  z-index: 999;
  left: 0rem;
  bottom: 0rem;
  background: rgba($color: #fff, $alpha: 0.8);
  border-radius: 0.3rem;
  padding: 0.5rem;
  color: #000;
  display: flex;
  flex-direction: column;
  & i {
    color: #000;
    display: flex;
    margin: 0;
    &::before {
      content: "";
      background: var(--color);
      left: 0;
      top: 0;
      width: 1rem;
      height: 1rem;
      margin-right: 0.5rem;
      opacity: 0.7;
    }
  }
}

@media (max-width: 575.98px) {
  .legend {
    left: auto;
    bottom: auto;
    right: 0rem;
    top: 0rem;
  }
}
