@use "assets/scss/front/abstracts" as *;

.sdg-faq{
    &__wrapper{
        max-width: 80%;
    }
    &__accordion-toggler{
        width: 100%;
        background-color: #132442;;
        border: none;
        color: $color-secondary-light;
        text-shadow: $box-shadow-aqua;
    }
    &__accordion-triangle-up{
        width: 0;
        height: 0;
        border-left: 7px solid transparent;
        border-right: 7px solid transparent;
        border-bottom: 12px solid $color-secondary-light;
    }
    &__accordion-triangle-down{
        width: 0;
        height: 0;
        border-left: 7px solid transparent;
        border-right: 7px solid transparent;
        border-top: 12px solid $color-secondary-light;
    }
    &__accordion-toggler:hover{
        color: #9cdff8;
        .sdg-faq__accordion-triangle-up{
            border-bottom: 12px solid #9cdff8;
        }
        .sdg-faq__accordion-triangle-down{
            border-top: 12px solid #9cdff8;
        }
    }
    &__accordion-text{
        opacity: 0.7;
    }
    &__title{
        text-align: center;
    }
}

@media screen and (max-width:575.98px) {
    .sdg-faq{
        &__wrapper{
            max-width: 95%;
        }
    }
}