// $color-admin-primary: #002663;
$color-admin-primary: #101D35;
$color-admin-primary-2: #053960;
$color-admin-grey: #666;
$color-admin-grey-2: #DDD;
$color-admin-danger: #FD5457;
$color-admin-success: #30D569;

$font-size-small: 14px;
$font-size-smaller: 12px;

$white: #FFFFFF;
$black: #000;
