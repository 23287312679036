@use "assets/scss/admin/main" as *;

.sdg-admin-manage {
  &__file-btn {
    cursor: pointer;
    color: rgba($black, .38);
    font-size: $font-size-small;
    border: 1px solid #E6E6E6;
    background-color: #E6E6E6;
    padding: .2rem 2.5rem;
    border-radius: 5px;
    display: inline-block;
  }

  &__upload-text {
    color: rgba($black, .38);
    font-size: $font-size-small;
    margin-left: 1rem;
  }

  &__input--file {
    border: 1px solid $color-admin-grey-2!important;
    padding: .5rem!important;
    height: 3rem!important;
  }

  &__hidden-file-btn {
    cursor: pointer;
    position: absolute;
    display: none;
    opacity: 0;
    left: 2rem;
  }

  &__image-preview{
    max-width: 75px;
    height: auto;
    margin: 0px 10px 0px 0px;
  }

  &__image-detail{
    white-space: pre-line;
  }

  &__close-button{
    cursor: pointer;
    vertical-align: top;
    margin: 0px 0px 0px 10px;
  }
}