@use "assets/scss/admin/abstracts" as *;

%base-btn {
  border: none !important;
  color: white;
  padding: 7px 25px !important;
}

.sdg-button-rectangle {
  @extend %base-btn;
  background-color: $color-admin-primary;
  border-radius: 5px;
  &:hover {
    text-decoration: none;
    background-color: #ffffff;
    color: black;
  }
}

.sdg-button-oval {
  @extend %base-btn;
  background-color: $color-admin-primary;
  border-radius: 25px;
  padding: 0.5rem 3rem;
  transition: 0.2s all;
  &:hover {
    background-color: darken(#fff, 10%);
    color: black;
  }
  &:disabled {
    background-color: $color-admin-grey;

    &:hover {
      color: $white;
    }
  }
}

.sdg-button-date {
  @extend %base-btn;
  background-color: #4bc8f5 !important;
  border-radius: 5px !important;
  &:hover {
    text-decoration: none;
    background-color: darken($color: #4bc8f5, $amount: 20%) !important;
  }
}
