.sdg-btn-outline {
  text-align: center;
  text-transform: uppercase;
  padding: 0.5rem 3rem;
  color: $color-secondary-light;
  background-color: transparent;
  border: $border-secondary;
  border-radius: 10px;
  transition: 0.2s all;

  // Placeholder
  &:hover {
    background-color: $bg-primary-light;
    color: $white;
    border: 1px solid $bg-primary-light;
  }
}

%glow-style {
  background: linear-gradient(100.88deg, #24b9f2 33.29%, #58f0f0 109.22%);
  box-shadow: 0px 8px 24px rgba(26, 201, 218, 0.44);
  border-radius: 8px;
  color: $bg-dark;
  font-weight: 700;
  border: none;
}

.sdg-btn-glow {
  @extend %glow-style;
  padding: 0.6rem 2rem;
  &:hover {
    text-decoration: none;
    color: $bg-dark;
    opacity: 0.8;
  }

  &.disabled {
    background: #6c757d;
    box-shadow: none;
    color: #101d35;
    &:hover {
      opacity: 0.65;
    }
  }
}

.sdg-link-glow {
  @extend %glow-style;
  padding: 0.8rem 1.5rem;
  &:hover {
    text-decoration: none;
    color: $bg-dark;
    opacity: 0.8;
  }
}

@media (max-width: 575.98px) {
  .sdg-link-glow,
  .sdg-btn-glow {
    font-size: 0.875rem;
  }
}
