@use "assets/scss/admin/main.scss" as *;

.sdg-admin-manage-article {
  max-width: 70%;
  max-height: 33rem;
  overflow-y: scroll;
  border-radius: 10px!important;
  &__header {
    margin-bottom: .5rem;
  }

  &__top-heading {
    color: $color-admin-primary-2;
    font-weight: 500;
    font-size: 1.5rem;
  }

  &__meta-text {
    font-weight: 400;
    font-size: $font-size-smaller;
    color: #484847;
  }

  &__hero {
    width: 100%;
    height: 20.3125rem;
  }

  &__image {
    width: 100%;
    height: 100%;
  }

  &__body {
    margin-top: 3rem;
  }
}