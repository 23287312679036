@use "assets/scss/front/abstracts" as *;

.sdg-artikel-detail{
  &__wrapper{
    max-width: 75%;
  }
  &__article-type{
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    filter: drop-shadow(0px 8px 16px rgba(26, 201, 218, 0.22));
    img {
      width: 2rem;
      margin: 1rem 0;

      &:not(:last-child) {
        margin-right: 1rem;
      }
    }
  }
  &__image{
    margin: 25px 0px;
    width: 100%;
    height: 27.5rem;

    @media (max-width: 575.98px) {
      height: 15rem;
    }
  }
  &__source{
    text-align: center;
    color: $white;
    opacity: 0.7;
    margin: 0px 0px 20px 0px;
  }
  &__text{
    white-space: pre-wrap;
    color: $white;
    opacity: 0.7;
  }
}

@media screen and (max-width:575.98px) {
  .sdg-artikel-detail{
    &__wrapper{
      max-width: 95%;
    }
  }
}