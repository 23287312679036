// font
$primary-font: 'Lato', sans-serif;
$font-size-small: 14px;
$font-size-smaller: 12px;

// colour
$bg-dark: #101D35;
$bg-primary-light: #132442;

$color-secondary-light: #4BC8F5;
$white: #FFFFFF;
$black: #000;

// box-shadows
$box-shadow-aqua: 0 .5rem 2rem -1.3rem #24B9F2;

// borders
$border-secondary: 1px solid $color-secondary-light;

// extend
%text-blue-linier {
  background: -webkit-linear-gradient(#24B9F2, #58F0F0);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
