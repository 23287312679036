.sdg-datepicker {
  position: relative;
  &__input-date {
    padding: 7px 25px 7px 10px;
    color: #002663;
    border: 1px solid #dddddd;
    border-radius: 8px;
    min-width: 280px;
  }
  &__input-date-dropdown {
    position: absolute;
    right: 1.2rem;
    top: 1rem;
  }
}

.react-datepicker {
  &__close-icon {
    right: 2.5rem !important;
  }
}
