@use "assets/scss/front/abstracts" as *;

.sdg-article-item__article {
  display: flex;
  flex-direction: row;
  margin: 4rem 0 5rem 0;
  padding-left: 15px;
  padding-right: 15px;
  justify-content: space-between!important;

  &-list {
    padding: 0px 1.5rem !important;
    margin: 1.5rem 0px;
    
    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }
  &-image{
    max-width: 30rem;
    height: 17.5rem;
    border-radius: 20px;
    overflow: hidden;

    @media (max-width: 1399.98px) {
      width: 15rem;
      height: 10rem;
    }

    @media (max-width: 575.98px) {
      width: 20rem;
      height: 15rem;
    }
    img{
      width: 100%;
      height: 100%;
    }
  }

  &-type {
    margin-bottom: 1.25rem;
    filter: drop-shadow(0px 8px 16px rgba(26, 201, 218, 0.22));
    img {
      width: 2rem;
      margin-right: 1rem;
      margin-top: 1rem;
    }
  }
  &-title {
    font-size: 1.25rem;
    line-height: 34px;
    text-transform: uppercase;
    margin-bottom: 1.25rem;
  }
  &-text {
    opacity: 0.7;
    margin-bottom: 1.25rem;
    display: inline-block;
    min-width: 25rem;
    max-height: 4rem;
    overflow: hidden;
    text-overflow: ellipsis;

    @media (max-width: 1399.98px) {
      min-width: 18rem;
      height: 10rem;
    }
  }
  &-footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    span {
      &:nth-child(1) {
        color: $color-secondary-light;
        text-shadow: 0px 4px 16px rgba(26, 201, 218, 0.84);
      }
      &:nth-child(2) {
        font-size: $font-size-small;
      }
    }
  }
  &-link{
    text-decoration: none;
  }
  &-link:hover{
    text-decoration: none;
    color: $color-secondary-light;
    text-shadow: 0px 4px 16px rgba(26, 201, 218, 0.84);
    opacity: 0.7;
  }
}

@media screen and (max-width:575.98px) {
  .sdg-article-item__article {
    margin: 2rem 0 3rem 0;
    &-list {
      margin: 0 0 1.5rem 0;
    }
    &-title {
      font-size: 16px;
      line-height: 24px;
    }
    &-text {
      opacity: 0.7;
      margin-bottom: 1.25rem;
      display: none;
    }
  }
}