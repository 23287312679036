@import "assets/scss/front/abstracts/variables";

.sdg-navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  &__logo {
    display: flex;
    flex-direction: row;
    padding: 25px 0;
    img {
      width: 90%;
      &:last-child {
        margin: 0 10px;
      }
    }
  }
  &__menu {
    display: flex;
    flex-direction: row;
    font-size: $font-size-small;
    white-space: nowrap;
  }
  &__menu-list {
    margin: 0 40px;
    a {
      color: $white;
      &:hover {
        text-decoration: none;
        @extend %text-blue-linier;
      }
    }
    &--active {
      @extend %text-blue-linier;
    }
  }
}

@media (max-width: 575.98px) {
  .sdg-navbar {
    &__logo {
      padding: 0;
      width: 70vw;
      img {
        width: 100%;
      }
    }
    &__menu {
      flex-direction: column!important;
    }
    &__menu-list {
      margin: 0.8rem 0 0 0;
    }
  }
}