@use "assets/scss/front/abstracts" as *;

.sdg-search-bar {
  @extend %input;
  color: $white;
  padding: .5rem 1rem;
  border-color: $color-secondary-light;
  border-radius: 5px;
  filter: drop-shadow(0px 8px 24px rgba(26, 201, 218, 0.56));
  width: 20%;

  @media (max-width: 575.98px) {
    width: 80%;
  }

  &::placeholder {
    font-size: $font-size-small;
    text-transform: capitalize;
    color: rgba($white, .5) !important;
  }
}