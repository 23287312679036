@use "assets/scss/front/abstracts" as *;

.sdg-komunikasi__form{
    p{
        opacity: 0.7;
    }
    input[type='text'],input[type='tel'],input[type='email'],textarea{
        border: 1px solid #4BC8F5;
        filter: drop-shadow(0px 8px 24px rgba(26, 201, 218, 0.56));
        border-radius: 4px;
        background-color: transparent;
        color: $white;
    }
    input[type='tel']:valid,input[type='email']:valid,textarea:focus{
        background-color: transparent;
        color: $white;
    }
}