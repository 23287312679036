.container-fluid {
  padding-left: 50px!important;
  padding-right: 50px!important;
}

@media screen and (max-width:575.98px) {
  .container-fluid {
    padding-left: 25px!important;
    padding-right: 25px!important;
  }

  .navbar-dark .navbar-toggler {
    border: none;
  }
  
  .navbar-dark .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 1%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='3' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  }
}