@use "assets/scss/admin/abstracts" as *;

.dropdown.nav-item {
  list-style-type: none;
}

.sdg-admin-header {
  &__brand {
    width: 15%;
    padding: 1.5rem 2rem;
    background-color: $color-admin-primary;
  }

  &__logo {
    width: 3rem;

    &:not(:last-child) {
      margin-right: 1rem;
    }
  }

  &__sidebar-toggler {
    cursor: pointer;
  }

  &__image {
    width: 1.5rem;
    height: 1.5rem;
  }

  &__dropdown {
    font-size: $font-size-smaller!important;
    margin-left: .5rem;
    color: $black!important;
    background-color: transparent!important;
    border: none!important;
    display: flex!important;
    align-items: center;

    &::after {
      font-size: 1rem;
      margin-left: 1rem!important;
    }
  }
}