@use "assets/scss/admin/main.scss" as *;

.sdg-admin-komunikasi-publik{
  &__label{
    color: $color-admin-grey;
    font-size: $font-size-small;
    margin-right: 30px;
  }
  &__label-subject{
    color: $color-admin-grey;
    font-size: $font-size-small;
    margin: 0px;
  }
  &__label-title{
    font-size: 20px;
  }
  &__content{
    color: $black;
    font-size: 16px;
    opacity: 0.7;
    margin-right: 30px;
  }
  &__border{
    border-bottom: 1px solid $color-admin-grey-2;
  }
}