@use "assets/scss/front/abstracts" as *;

.sdg-tentang {
  margin-bottom: 10rem;
  max-width: 70%;
  
  &-content {
    &__text {
      @extend %tentang-text;
    }
    &__title{
      font-weight: 700;
    }
  }

  &-regulasi {
    display: flex;
    flex-direction: column;
    align-items: center;

    &__text {
      @extend %tentang-text;
      // max-width: 60%;
      text-align:center;
    }
  }
}

@media screen and (max-width:575.98px) {
  .sdg-tentang {
    margin-bottom: 10rem;
    max-width: 100%;

    &-content {
      &__text {
        max-width: 100%;
      }
      &__title{
        white-space: pre-wrap;
        text-align: center;
      }
    }

    &-regulasi {
      &__text {
        max-width: 100%;
      }
    }
    
  }  
}
