@use "assets/scss/admin/main.scss" as *;

.sdg-datatable{
  &__content-red{
    color: $color-admin-danger;
  }
  &__content-green{
    color: $color-admin-success;
  }
  &-content{
    background: #FFFFFF;
    border: 1px solid #DDDDDD;
    box-sizing: border-box;
    border-radius: 8px;
  }
  &__button{
    &-edit,&-delete,&-detail{
      margin-right: 7px;
    }
    &-detail{
      border: 1px solid #165FBB;
      border-radius: 4px;
      background-color: white;
      width: 2rem;
      height: 2rem;
      padding: 0;
    }
    &-detail:hover{
      background-color: #165FBB;
      color: white;
      path{
        fill: white;
      }
    }
    &-edit{
      border: 1px solid #30D569;
      border-radius: 4px;
      background-color: white;
      width: 2rem;
      height: 2rem;
      padding: 0;
    }
    &-edit:hover{
      background-color: #30D569;
      color: white;
      path {
        fill: white;
      }
    }
    &-delete{
      border: 1px solid #FD5457;
      border-radius: 4px;
      background-color: white;
      width: 2rem;
      height: 2rem;
      padding: 0;
    }
    &-delete:hover{
      background-color: #FD5457;
      color: white;
      path{
        fill: white;
      }
    }
    
  }
}

#sdg-datatable-container {
  &__table-head{
    color: black;
  }
  
  .table-head{
    .input-group{
      align-items: center;
    }
    .buttons-excel{
      background-color: #009EDB;
      border: none;
      .fa {
        &::before {
          content: '\f02f';
        }
      }
      span::after {
        content: 'Download';
        margin-left: 10px;
      }
    }
    
  } 
}

#sdg-datatable-table {
  &-head {
    padding: 20px 15px 0 15px;
  }
  &-foot {
    padding: 0 15px 20px 15px;
    align-items: center;
  }
}

.table-foot{
  .page-link{
    color: #053960 !important; ;
  }
  .active{
    .page-link{
      color: white !important;
      background-color:#053960 !important;
      border-color:#053960 !important;
    }
  }
}