@import "./abstracts";
@import "./components";
@import "./vendors-extensions/bootstrap";
@import "./vendors-extensions/leaflet";

//global style
html {
  scroll-behavior: smooth;
}

.App {
  font-family: $primary-font;
  background-color: $bg-dark;
  color: $white;
  min-height: 100vh;
}
