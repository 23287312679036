@use "assets/scss/admin/main.scss" as *;

.sdg-admin-manage__footer {
  border-top: 1px solid $color-admin-grey-2;
  margin: 0 .1rem;
  background-color: $white;
  padding: 1rem 2rem;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}