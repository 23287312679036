@use 'assets/scss/front/abstracts/variables' as *;

.sdg-masukan-card {
  &-container {
    @media (max-width: 575.98px) {
      flex: 0 0 33.33%;
    }

    @media (max-width: 320.98px) {
      flex: 0 0 50%;
    }
  }

  &-item {
    background-color: $bg-primary-light;
    padding: 1rem;
    margin: 1rem .8rem 1rem 0;
    border-radius: 7px;
    box-shadow: 0 .2rem .5rem rgba($black, .5);
    cursor: pointer;
    transition: .2s all;

    @media (max-width: 575.98px) {
      padding: .6rem;
      margin-bottom: 0;
    }

    &:hover {
      background-color: lighten($bg-primary-light, 8%);
    }

    &--empty {
      background-color: transparent;
      box-shadow: none;
      cursor: default;

      &:hover {
        background-color: transparent;
      }
    }

    &__image {
      width: 100%;
      height: 100%;
    }
  }
}