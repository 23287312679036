@use "assets/scss/admin/abstracts" as *;

.sdg-admin {
  &-container {
    color: $black;
    width: 100%;
    min-height: 100vh;
    display: flex;
    background-color: $color-admin-primary;
  }

  &-content {
    padding: 1.5rem 1.5rem 2rem; // Change this in-demand
    width: 100%;
    min-height: 100vh;
    background-color: #E5E5E5;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &__header {
      margin: 0px 0px 20px 0px;
      display: flex;
      align-items: center;

      &-title {
        margin-bottom: 0;
        margin-right: auto;
      }
    }
  }
}