@use "assets/scss/admin/abstracts" as *;

.sdg-admin-sidebar {
  max-height: 100vh;
  min-width: 14.85%;
  background-color: $color-admin-primary;
  padding: 2rem 0;

  &__nav-item {
    height: 6.5%;
  }

  &__icon {
    margin: 0 .8rem;
  }

  &__nav-link {
    display: flex;
    align-items: center;
    color: $white;
    font-size: $font-size-small;
    padding: .5rem;
    width: 100%;
    height: 100%;
    transition: .2s all;

    &:hover {
      background-color: rgba($white, .1);
      text-decoration: none;
      color: $white;
      border-right: 5px solid $white;
    }

    &--active {
      background-color: rgba($white, .1);
      border-right: 5px solid $white;
    }
  }

  &__separator {
    font-family: 'Roboto', sans-serif;
    margin-top: 1rem;
    text-transform: uppercase;
    font-size: $font-size-smaller;
    color: rgba($white, .7);
    display: flex;
    align-items: center;
    justify-content: space-between;
    white-space: nowrap;

    &::before {
      content: "";
      height: 2px;
      width: 2rem;
      background-color: rgba($white, .5);
      margin-right: 0.5rem;
    }

    &::after {
      content: "";
      height: 2px;
      width: 100%;
      background-color: rgba($white, .5);
      margin-left: 0.5rem;
    }
  }
}