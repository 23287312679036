@use "../abstracts/" as *;

.ck.ck-editor__top.ck-reset_all {
  border-top: 1px solid $color-admin-grey-2;
  border-right: 1px solid $color-admin-grey-2;
  border-left: 1px solid $color-admin-grey-2;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}

.ck.ck-icon {
  width: 1rem;
  height: 1rem;
}

.ck.ck-sticky-panel{
  width: 50%;
  &__content {
    border-right: 2px solid $color-admin-grey-2;
  }
}


.ck.ck-content.ck-editor__editable.ck-rounded-corners.ck-editor__editable_inline {
  border-top: none;
  border-color: $color-admin-grey-2;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;

  &.ck-focused {
    border-top:none;
    border-color: $color-admin-grey-2;
    border-width: 1px;
    box-shadow: none;
    outline: none;
  }
}