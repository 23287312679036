.sdg-front {
  &-card {
    margin-bottom: 3rem;
    &__text {
      font-weight: 700;
      line-height: 28px;
      text-transform: uppercase;
      margin-bottom: 1.5rem;
    }

    &__download-btn {
      a {
        color: $color-secondary-light;
        text-shadow: 0px 4px 16px rgba(26, 201, 218, 0.84);
        line-height: 28px;

        &:hover {
          text-decoration: none;
          cursor: pointer;
          color: $white;
        }
      }
    }

    @media (max-width: 578.98px) {
      &-card {
        margin-bottom: 1.5rem;
  
        &__text {
          margin-bottom: 1rem;
        }
  
        &__download-btn {
          min-height: 50px;
          &::after {
            content: "";
            position: absolute;
            bottom: 0;
            left: 15px;
            right: 15px;
            width: 90%;
            height: 1px;
            border-bottom: 1px solid rgb(75, 200, 245, 0.3);
          }
        }
      }
    }
  }
}