nav.bg-light {
  background-color: #FFFFFF!important;
}

.table {
  td, th {
    border-bottom: 1px solid #dee2e6;
  }
  thead {
    th {
      border-top: none!important;
      border-bottom: none!important;
    }
  }
}