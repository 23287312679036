@use 'assets/scss/front/abstracts' as *;

.sdg-masukan {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__header {
    display: flex;
    flex-direction: column;
    align-items: center;
    &-image {
      // box-shadow: $box-shadow-aqua;
      width: 80%;
      // // filter: drop-shadow(0px 8px 24px rgba(26, 201, 218, 0.56));
      // filter: drop-shadow(0px 5px 10px rgba(26, 201, 218, 0.56));

      @media (max-width: 575.98px) {
        width: 100%;
      }
    }

    &-text {
      line-height: 26px;
      text-align: center;
      width: 55%;

      @media (max-width: 575.98px) {
        font-size: $font-size-small;
        width: 84%;
      }
    }
  }
  &-modal {
    &-success{
    @media (max-width: 575.98px) {
      min-width: 100%!important;
      margin: 0!important;
      padding: 0px 15px;
    }

    .modal-content {
      background-image: linear-gradient(35.52deg, rgba(36, 185, 242, 0.19) 58%, rgba(88, 240, 240, 0.72) 99.77%);
      background-color: transparent;
      backdrop-filter: blur(30px);
      border-radius: 20px;
      padding: 1rem 2rem;

      @media (max-width: 575.98px) {
        border-radius: 0;
      }
    }

    }
  }
  &-modal {
    min-width: 50rem!important;

    @media (max-width: 575.98px) {
      min-width: 100%!important;
      margin: 0!important;
    }

    .modal-content {
      background-image: linear-gradient(35.52deg, rgba(36, 185, 242, 0.19) 58%, rgba(88, 240, 240, 0.72) 99.77%);
      background-color: transparent;
      backdrop-filter: blur(30px);
      border-radius: 20px;
      padding: 1rem 1.5rem;

      @media (max-width: 575.98px) {
        border-radius: 0;
      }
    }

    &__header {
      border-bottom: none !important;

      &-success{
        border-bottom: none !important;
      }
      &-row {
        @media (max-width: 575.98px) {
          justify-content:stretch!important;
        }

        @media (max-width: 320.98px) {
          justify-content: center!important;
        }
      }

      .close {
        color: $white!important;
        font-weight: 100;
        font-size: 30px;
        transition: .2s all;
        opacity: 10;
      }

      &-image-col {
        @media (max-width: 575.98px) {
          padding: 0!important;
          margin-right: .8rem;
        }
      }

      &-text-col {
        @media (max-width: 575.98px) {
          flex: 0 0 80%!important;
          max-width: 70%!important;
        }

        @media (max-width: 320.98px) {
          justify-content: center!important;
        }
      }

      &-image-container {
        width: 5rem;
        height: 5rem;
        overflow: hidden;
        border-radius: 10px;

        @media (max-width: 575.98px) {
          width: 3rem;
          height: 3rem;
        }
      }

      &-image {
        width: 100%;
        height: 100%;
      }

      &-text {
        text-transform: uppercase;
        color: $white;
        
        &--small {
          font-size: .75rem;
          font-weight: 400;
          letter-spacing: 0.05em;
          color: rgba($white, .5);

          @media (max-width: 575.98px) {
            margin-bottom: .6rem;
          }
        }

        &--medium {
          font-size: 1rem;
          line-height: 26px;
          text-transform: none;
          font-weight: 400;
          opacity: .7;

          @media (max-width: 575.98px) {
            font-size: $font-size-small;
          }
        }
      }
      &-text-success-name{
        color: $white;
        margin:0px;
        font-weight: 700;
      }
      &-text-success-email{
        color: $white;
        margin:0px;
      }
    }

    &__footer {
      border-top: none!important;
    }
    &__footer-success {
      border-top: none!important;
      justify-content: center !important;
      margin-bottom: 25px;
    }
    &__content{
      &-image{
        max-width: 48px;
      }
    }
  }

  &-form {
    &__label {
      color: $white;
    }

    &__input {
      @extend %input;
      padding: 1.5rem 1rem!important;
      color: $white!important;
    }

    &__textarea {
      @extend %input;
      height: 10rem!important;
      padding: .8rem!important;
      color: $white!important;
    }
  }

  &__btn-container {
    width: 100%;
    display: flex;
    justify-content: space-between;

    @media (max-width: 575.98px) {
      flex-direction: column;
      margin-bottom: 0!important;
    }
  }
}

.sdg-btn-outline {
  @media (max-width: 575.98px) {
    order: 1;
  }
}

.sdg-btn-glow--modal {
  @media (max-width: 575.98px) {
    order: 0;
    margin-bottom: 1rem;
  }
}