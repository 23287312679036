@use "assets/scss/admin/main.scss" as *;

.sdg-admin-masukan{
  &__label{
    color: $color-admin-grey;
    font-size: $font-size-small;
    margin-right: 30px;
  }
  &__image{
    max-width: 32px;
    height: auto;
    border-radius: 6px;
    margin-right: 10px;
  }
}