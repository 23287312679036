@use "assets/scss/front/abstracts/variables";

.sdg-subheadertitle {
  display: flex;
  align-items: center;
  flex-direction: column;

  &__subtitle {
    @extend %text-blue-linier;
    margin-bottom: 20px;
    font-weight: 700;
    font-size: 32px;
    line-height: 57px;
    text-shadow: 0px 8px 24px rgba(26, 201, 218, 0.56);
  }
  &__author {
    margin-bottom: 0px;
  }
}

@media screen and (max-width:575.98px) {
  .sdg-subheadertitle {
    &__subtitle {
      font-size: 24px;
      line-height: 32px;
    }
  }
}